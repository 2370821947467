import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

interface WaitlistState {
  waitlist: {
    industryId: string;
    occupationId: string;
    message: string;
    utm_source: string;
    utm_medium: string;
    utm_campaign: string;
    utm_content: string;
  };
  setWaitlist: (value: WaitlistState["waitlist"]) => void;
}

export const useWaitlistStore = create<WaitlistState>()(
  devtools(
    persist(
      (set) => ({
        waitlist: {
          industryId: "",
          occupationId: "",
          message: "",

          // データ分析用
          utm_source: "",
          utm_medium: "",
          utm_campaign: "",
          utm_content: "",
        },
        setWaitlist: (value) => set((_) => ({ waitlist: value })),
      }),
      { name: "waitlistStore" },
    ),
  ),
);
